<template>
  <div v-if="Object.values(isDataReady).every(v => v === true)" class="h-full w-full">
    <div v-if="!keywords.length" class="flex h-full w-full flex-col">
      <div class="m-auto">
        <div class="flex h-5 w-full items-center space-x-2">
          <ExclamationCircleIcon class="h-4 w-4 text-gray-400" />
          <span class="block text-xs">Select an SEC Filing Form Type to Visualize</span>
        </div>
      </div>
    </div>
    <span v-else>
      <div class="flex h-full flex-col space-y-4">
        <div class="flex-1 overflow-y-scroll rounded border border-gray-800">
          <TableNewsSimple :data="newsPreview" />
        </div>
      </div>
    </span>
  </div>
  <div v-else class="flex flex-col pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
</template>
<script setup>
import { ref, onMounted, inject, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import TableNewsSimple from '@/components/table/TableNewsSimple.vue';
import useEmitter from '@/composeables/emitter';

const emitter = useEmitter();
const $http = inject('http');
const store = useStore();

const isDataReady = ref({ sec: false });

const selectedId = ref(null);
const keywords = ref([]);
const newsPreview = ref([]);
const alertConfig = ref({});
const feeds = ref([]);

async function fetchNewsFeeds() {
  isDataReady.value.sec = false;
  const response = await $http.get('/news_feeds');
  feeds.value = response.data.news_feeds.filter(x => !store.getters.defaultFeeds.includes(x.name));
  isDataReady.value.sec = true;
}

async function refreshPreview() {
  isDataReady.value.sec = false;
  let response = await $http.get('/data/news?want_firehose=true', { params: apiFeedState.value });
  newsPreview.value = response.data.data;
  isDataReady.value.sec = true;
}

onBeforeMount(() => {});

onMounted(() => {
  if (props.alert) {
    keywords.value = props.alert.config.keywords;
    refreshPreview();
  }
  emitter.$on('config:alert', data => {
    alertConfig.value = data.config;
    keywords.value = data.config.keywords;
    refreshPreview();
  });
  fetchNewsFeeds();
});

const apiFeedState = computed(() => {
  function getKeywordsList() {
    return keywords.value?.map(e => {
      return `Form: ${e} `;
    });
  }

  return {
    keywords: getKeywordsList()
  };
});

const props = defineProps({
  alert: {
    type: Object,
    default: () => null
  }
});
</script>
